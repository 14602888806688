h1 {
  text-align: center;
}
.chatbox-container {
  display: flex;
  flex-direction: column; /* Change row to column to center vertically */
  justify-content: center;
  align-items: center; /* Align items to the center horizontally */
  margin: auto; /* Center the container */
  background-color: white;
  padding-top: 20px;
  border: solid #dfdff3 1px;
  border-radius: 4px;
}
.logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 130px;
  margin: 0 auto;
}
.logo-wrap img {
  max-width: 100%;
}
.chatbox-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid #dfdff3 1px;
  height: 30px;
  width: initial;
}
.chatbox-header h1 {
  margin-top: 0px;
}
.text-container {
  height: 200px;
  border: solid #dfdff3 1px;
  background-color: #f4f4f8;
  border-radius: 4px;
  margin: 1%;
}

.text-box {
  width: 85%;
  max-width: 320px;
  max-height: 50%;
  background-color: #f4f4f8;
  padding: 18px;
  /* padding-left: 16px;
    padding-right: 16px; */
  border: none;
  outline: none;
  font-size: 14px;
}

.messages-container {
  background: #f8f8f8;
  padding: 3% 2% 0% 3%;
  width: 320px;
  margin: 9px auto 2px auto;
  border-radius: 3px;
  border: 1px solid #ebebeb;
  color: #898d9f;
  text-align: center;
  overflow-y: scroll;
}

.send_text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.send-button {
  /* Green */
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  background: linear-gradient(45deg, #08755d, #0b9275);
}

.send-button:active {
  background-color: #08755d;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.chat-widget-container {
  bottom: initial;
  top: initial;
  height: 100vh;
  width: 320px;
  margin-top: 20px;
}

.rcw-widget-container {
  bottom: initial;
  right: initial;
  width: 320px;
  height: 50px;
  top: 184px;
}

.rcw-conversation-container {
  height: initial;
  border-radius: 0px;
  /* box-shadow: 0 2px 10px 1px #b5b5b5; */
  width: 320px;
  position: relative;
  min-width: initial;
}

.rcw-conversation-container .rcw-header {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  color: #000;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 0 25px;
}

.rcw-conversation-container .rcw-title {
  font-size: 14px;
  margin: 0;
  padding: 15px 0;
}

.rcw-messages-container {
  background-color: #fff;
  height: 41vh;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.rcw-picker-btn {
  display: none;
  background: #f4f7f9;
  border: 0;
  cursor: pointer;
}
.rcw-launcher {
  display: none;
}
.rcw-timestamp {
  display: none;
}
#messages::-webkit-scrollbar {
  width: 10px;
}

#messages::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

#messages::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
